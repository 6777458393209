import {setLoading, removeLoading} from "@/lib/loading";
import api from "@/api";
import {showMessage} from "@/lib/toasted";
import {showConfirm} from "@/lib/swal2";
import {DeliveryToOtherRegion, DeliveryToMoscow, DeliveryToTheOffice} from "@/config/subdivision_delivery_types";
import router from "@/router";

export default {
    namespaced: true,
    state: {
        isCartLoaded: false,
        isCartUpdating: false,
        isProductOrderCreating: false,
        cartLines: [],
        areSameAgenciesProducts: null,
        deliveryInfo: {
            type: null,
            region: null,
            city: null,
            address: null,
            street: null,
            house: null,
            recipientName: null,
            recipientEmail: null,
            contactNumber: null,
            optionalContactNumber: null,
            orderPurpose: null,
            orderOrganization: null,
            comment: null,
            splitOrder: null,
            isOrderUrgent: false,
        },
        deliveryTypes: [
            {key: 1, enumName: 'DeliveryToTheOffice', name: 'Доставка в офис ЛЛК-Интернешнл'},
            {key: 2, enumName: 'DeliveryToMoscow', name: 'Доставка по Москве'},
            {key: 3, enumName: 'DeliveryToOtherRegion', name: 'Доставка в другой регион'},
        ],
        userEmail: '',
        userPhoneNumber: ''
    },
    getters: {
        isCartLoaded: ({isCartLoaded}) => isCartLoaded,
        isCartUpdating: ({isCartUpdating}) => isCartUpdating,
        isProductOrderCreating: ({isProductOrderCreating}) => isProductOrderCreating,
        findProductInCart: (state, getters) => (productId, sizeId = null) => {
            return getters.cartLines.find((line) => line.productId === productId && (!sizeId || line.sizeId === sizeId))
        },
        longTermLines: ({ cartLines }) => cartLines.filter((cartLine) => cartLine.isLongTermProduction === 1),
        cartLines: ({ cartLines }) => cartLines.filter((cartLine) => cartLine.isLongTermProduction !== 1),
        areSameAgenciesProducts: ({areSameAgenciesProducts}) => areSameAgenciesProducts,
        deliveryTypes: ({deliveryTypes}) => deliveryTypes,
        deliveryInfo: ({deliveryInfo}) => deliveryInfo,
        deliveryType: ({deliveryInfo: {type}}) => type,
        deliveryRegion: ({deliveryInfo: {region}}) => region,
        deliveryCity: ({deliveryInfo: {city}}) => city,
        deliveryStreet: ({deliveryInfo: {street}}) => street,
        deliveryHouse: ({deliveryInfo: {house}}) => house,
        deliveryAddress: ({deliveryInfo: {address}}) => address,
        deliveryRecipientName: ({deliveryInfo: {recipientName}}) => recipientName,
        deliveryRecipientEmail: ({deliveryInfo: {recipientEmail}}) => recipientEmail,
        deliveryIsOrderUrgent: ({deliveryInfo: {isOrderUrgent}}) => isOrderUrgent,
        deliveryContactNumber: ({deliveryInfo: {contactNumber}}) => contactNumber,
        splitOrder: ({deliveryInfo: {splitOrder}}) => splitOrder,
        deliveryOptionalContactNumber: ({deliveryInfo: {optionalContactNumber}}) => optionalContactNumber,
        deliveryOrderPurpose: ({deliveryInfo: {orderPurpose}}) => orderPurpose,
        deliveryOrderOrganization: ({deliveryInfo: {orderOrganization}}) => orderOrganization,
        deliveryComment: ({deliveryInfo: {comment}}) => comment,
        isSelectedDeliveryToOtherRegion({deliveryInfo: {type}}) {
            return type === DeliveryToOtherRegion;
        },
        isSelectedDeliveryToTheOffice({deliveryInfo: {type}}) {
            return type === DeliveryToTheOffice;
        },
        isSelectedDeliveryToTheOrganization({deliveryInfo: {type}}) {
            return type === DeliveryToTheOffice
        },
        isSelectedDeliveryToMoscow({deliveryInfo: {type}}) {
            return type === DeliveryToMoscow;
        },
        anyProductBalanceIsNotEnough({cartLines}) {
            return cartLines.some(l => l.quantity > l.structuralSubdivisionProductBalance);
        },
        userEmail: ({userEmail}) => userEmail,
        userPhoneNumber: ({userPhoneNumber}) => userPhoneNumber,
        getTotal: ({cartLines}) => cartLines.reduce((a, b) => a + b.quantity, 0),
        canCreateOrder: ({isProductOrderCreating}, {anyProductBalanceIsNotEnough}) => !anyProductBalanceIsNotEnough && !isProductOrderCreating
    },
    mutations: {
        SET_CART_LOADED(state, loaded) {
            state.isCartLoaded = loaded;
        },
        SET_CART_UPDATING(state, updating) {
            state.isCartUpdating = updating
        },
        SET_PRODUCT_ORDER_CREATING(state, created) {
            state.isProductOrderCreating = created;
        },
        SET_CART_LINES(state, cartLines) {
            state.cartLines = cartLines;
        },
        SET_ARE_SAME_AGENCIES_PRODUCTS(state, areSameAgenciesProducts) {
            state.areSameAgenciesProducts = areSameAgenciesProducts;
        },
        UPDATE_CART_LINE(state, payload) {
            const line = state.cartLines.find((line) => line.productId === payload.productId);
            Object.assign(line, payload);
        },
        SET_DELIVERY_INFO(state, payload) {
            Object.assign(state.deliveryInfo, {...state.deliveryInfo, ...payload});
        },
        SET_USER_EMAIL(state, email) {
            state.userEmail = email;
        },
        SET_USER_PHONE_NUMBER(state, phoneNumber) {
            state.userPhoneNumber = phoneNumber.replace(/\D/g, '');
        },
        SET_DELIVERY_ADDRESS(state, address ) {
            state.deliveryInfo.address = address
        }
    },
    actions: {
        async getCartInfo({commit}) {
            setLoading();

            try {
                const {
                    data: {
                        status,
                        data: {
                            cartLines,
                            areSameAgenciesProducts
                        }
                    }
                } = await api.cart.getCart();

                commit('SET_CART_LINES', cartLines);

                let itemsInCart = 0;
                let inCartProductIds = [];

                cartLines.forEach((line) => {
                    itemsInCart += line.quantity;
                    if (inCartProductIds.indexOf(line.productId) === -1)
                        inCartProductIds.push(line.productId)
                });

                commit('cart/SET_ITEMS_IN_CART', itemsInCart, {root: true});
                commit('cart/SET_IN_CART_PRODUCT_IDS', inCartProductIds, {root: true});
                commit('SET_ARE_SAME_AGENCIES_PRODUCTS', areSameAgenciesProducts);
                commit('SET_CART_LOADED', true);
            } catch (e) {
            }

            removeLoading();
        },
        async getUserInfo({commit}) {
            setLoading();

            try {
                const {
                    data: {
                        status, data: {
                            email,
                            phone_number
                        }
                    }
                } = await api.cart.getSubdivisionUserInfo();

                if (status === 'ok') {
                    commit('SET_USER_EMAIL', email);
                    commit('SET_USER_PHONE_NUMBER', phone_number);
                }
            } catch (e) {
            }

            removeLoading();
        },
        async addProduct({commit, rootGetters}, {productId, sizeId = null, quantity = 1}) {
            setLoading();

            try {
                const {data: {status, message}} = await api.cart.addItem(productId, quantity, sizeId);

                if (status === 'ok') {
                    commit('cart/SET_ITEMS_IN_CART', rootGetters['cart/itemsInCart'] + quantity, {root: true});

                    if (rootGetters['cart/inCartProductIds'].indexOf(productId) === -1) {
                        commit('cart/SET_IN_CART_PRODUCT_IDS', [...rootGetters['cart/inCartProductIds'], productId], {root: true});
                    }
                }

                removeLoading();
                showMessage(status, message);

                return status === 'ok';
            } catch (e) {
                removeLoading()
                return false;
            }
        },
        async addProductUpdateLine({commit, dispatch, getters}, {productId, sizeId = null, quantity = 1}) {
            const {isCartUpdating} = getters;

            if (isCartUpdating) {
                return false;
            }

            const {findProductInCart} = getters;
            const line = findProductInCart(productId);

            if (line && (line.quantity + quantity) <= line.structuralSubdivisionProductBalance) {
                if (await dispatch('addProduct', {productId, sizeId, quantity})) {
                    commit('SET_CART_UPDATING', true);

                    await Promise.all([dispatch('getCartInfo'), dispatch('account/getUserInfo', null, {root: true})]);

                    // commit('UPDATE_CART_LINE', {
                    //     ...line,
                    //     quantity: line.quantity + quantity
                    // });

                    commit('SET_CART_UPDATING', false);
                }
            }
        },
        async removeProduct({commit, getters, rootGetters}, {
            productId,
            sizeId = null,
            quantity = 1,
            completely = false
        }) {
            setLoading();

            const {findProductInCart} = getters;
            const line = findProductInCart(productId);

            if (completely) {
                quantity = line.quantity;
            } else {
                completely = quantity >= line.quantity;
            }

            try {
                const {data: {status, message}} = await api.cart.removeItem(productId, quantity, completely, sizeId);
                removeLoading();
                showMessage(status, message);

                return status === 'ok';
            } catch (e) {
                removeLoading()
                return false;
            }
        },
        async removeProductUpdateLine({commit, dispatch, getters}, {
            productId,
            sizeId = null,
            quantity = 1,
            completely = false
        }) {
            const {isCartUpdating} = getters;

            if (isCartUpdating) {
                return false;
            }

            const {findProductInCart} = getters;

            const line = findProductInCart(productId);

            if (completely) {
                quantity = line.quantity;
            } else {
                completely = quantity >= line.quantity;
            }

            if (await dispatch('removeProduct', {productId, sizeId, quantity, completely})) {
                commit('SET_CART_UPDATING', true);

                await Promise.all([dispatch('getCartInfo'), dispatch('account/getUserInfo', null, {root: true})]);

                commit('SET_CART_UPDATING', false);
            }
        },
        async deleteLine({dispatch}, {productId, sizeId = null}) {
            const {isConfirmed} = await showConfirm('Подтвердите действие', 'Вы уверены, что хотите удалить данный товар из корзины?');

            if (isConfirmed) {
                await dispatch('removeProductUpdateLine', ({
                    productId: productId,
                    sizeId: sizeId,
                    completely: true
                }));
            }
        },
        async changeQuantity({commit, dispatch, getters: {cartLines, findProductInCart}}, {
            productId,
            sizeId = null,
            quantity
        }) {
            if (!isNaN(quantity))
                quantity = Number(quantity);

            const line = findProductInCart(productId, sizeId);

            if (line) {
                if (quantity >= line.structuralSubdivisionProductBalance)
                    quantity = line.structuralSubdivisionProductBalance;
                if (quantity <= 0)
                    quantity = 1;

                const difference = Math.abs(line.quantity - quantity);

                if (quantity > line.quantity && quantity <= line.structuralSubdivisionProductBalance) {
                    await dispatch('addProductUpdateLine', {productId, sizeId, quantity: difference});
                } else if (quantity < line.quantity) {
                    await dispatch('removeProductUpdateLine', {productId, sizeId, quantity: difference});
                }
            }

        },
        async selectDeliveryType({commit, dispatch, getters}, type) {
            await commit('SET_DELIVERY_INFO', {
                type
            });

            const {isSelectedDeliveryToOtherRegion, isSelectedDeliveryToTheOffice, isSelectedDeliveryToTheOrganization} = getters;

            commit('SET_DELIVERY_INFO', {
                city: !isSelectedDeliveryToOtherRegion ? "Москва" : null,
                address: isSelectedDeliveryToTheOffice ? "Садовническая улица, 75" : '',
                street: isSelectedDeliveryToTheOffice ? "Садовническая улица" : null,
                house: isSelectedDeliveryToTheOffice ? "75" : null,
                orderOrganization: isSelectedDeliveryToTheOrganization ? 'ООО «ЛЛК-Интернешнл»' : null,
                region: !isSelectedDeliveryToOtherRegion ? '' : null

            });
        },
        async createOrder({commit, dispatch, getters: {deliveryInfo}}) {
            setLoading();

            let success = false;
            commit('SET_PRODUCT_ORDER_CREATING', true);

            try {
                const {data: {status, message, data}} = await api.cart.createOrder(deliveryInfo);

                if (status === 'ok') {
                    success = true;
                    commit('cart/SET_IN_CART_PRODUCT_IDS', [], {root: true});
                    commit('cart/SET_ITEMS_IN_CART', 0, {root: true})
                    
                }

                showMessage(status, message);
            } catch (e) {
                const {status, message} = e.response.data

                if (!success) {
                    await dispatch('getCartInfo');
                }

                showMessage(status, message);
            } finally {
                commit('SET_PRODUCT_ORDER_CREATING', false);
                removeLoading();
            }

            if (success) {
                router.push({name: 'SuccessfulOrder'});
                commit('SET_DELIVERY_INFO', {
                    type: null,
                    region: null,
                    city: null,
                    address: null,
                    street: null,
                    house: null,
                    recipientName: null,
                    recipientEmail: null,
                    contactNumber: null,
                    optionalContactNumber: null,
                    orderPurpose: null,
                    orderOrganization: null,
                    comment: null,
                    isOrderUrgent: false
                });
            }
        },
    }
}
