<template>
  <router-view/>
</template>

<script>
import 'line-awesome/dist/line-awesome/css/line-awesome.min.css'

export default {
}
</script>

<style lang="sass">
@import './assets/sass/styles.sass'
</style>

<style lang="css" >
@import "./assets/css/bootstrap.min.css";
@import "./assets/fonts/futuris/styles.css";
@import "./assets/fonts/futurisc/styles.css";
@import "./assets/css/styles.css";
@import "./assets/css/reset.css";
</style>
