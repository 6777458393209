import Vue from 'vue'
import VueSweetalert2 from 'vue-sweetalert2';

// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css';

Vue.use(VueSweetalert2);

const swal = Vue.swal;

export const showConfirm = (title, message, options = {}) => {
    return swal({
        title: title,
        text: message,
        showCancelButton: true,
        showCloseButton: true,
        confirmButtonColor: "#22b794",
        cancelButtonColor: "#d33",
        confirmButtonText: "Да",
        cancelButtonText: "Нет",
        buttonsStyling: true,
        ...options
    });
};

export const showSuccess = (title) => {
    return swal({
        title: title,
        showCancelButton: false,
        showCloseButton: true,
        confirmButtonColor: "#22b794",
        cancelButtonColor: "#d33",
        confirmButtonText: "Хорошо",
        buttonsStyling: true,
    });
};

export const showPurchaseByDate = (title) => {
    return swal({
        title: title,
        showCancelButton: false,
        showCloseButton: true,
        confirmButtonColor: "#d33",
        confirmButtonText: "Хорошо",
        buttonsStyling: true,
        customClass: {
            container: 'purchase-by-date',
        }
    });
};
