import api from '@/api';

export default {
    namespaced: true,
    state: {
        staticList: [],
    },
    getters: {
        staticList: ({staticList}) => staticList,
    },
    mutations: {
        SET_STATIC(state, staticList) {
            state.staticList = staticList;
        },
    },
    actions: {
        async fetchStaticList({commit}) {
            try {
                const items = await api.staticPages.getPages();

                commit('SET_STATIC', items.data.data);
            } catch (e) {
                console.warn(e);
            }
        },
    }
}
