import store from '../store';
import {redirectToProperIndex} from "./helpers";
import {Dealer, StructuralSubdivisionUser} from "../config/roles";
import usersWithDisablePages from '@/config/users_with_disable_pages'

const disableGuard = (to, from, next) => {
    const token = store.state.account.token;
    const userNumber = store.state.account.accountInfo.dealerUniqueId;

    if (!token && !userNumber) {
        next({name: 'Login'})
    } else if (usersWithDisablePages.includes(+userNumber) && to.name === 'Home') {
        next('/education')
    } else if (usersWithDisablePages.includes(+userNumber)) {
        next('/404')
    }

    next();
}

const authGuard = (to, from, next) => {
    const token = store.state.account.token;
    const role = store.state.account.accountInfo.role;

    if (to.matched.some(record => record.meta.requiresAuth) && (!token || !role) && to.name !== 'AdminLogin') {
        next({name: 'Login'});
        return;
    }

    next();
};

const loginGuard = (to, from, next) => {
    const token = store.state.account.token;
    const role = store.state.account.accountInfo.role;

    if (token && role) {
        redirectToProperIndex(role);
        return;
    } else if (to.name === 'Account') {
        next({name: 'Login'})
    }

    next();
};

const dealerGuard = (to, from, next) => {
    const token = store.state.account.token;
    const role = store.state.account.accountInfo.role;

    if (!token || !role) {
        next({name: 'Login'})
    } else if (role != Dealer) {
        next('/404')
    }

    next();
}

const isDealerGuard = () => {
    const role = store.state.account.accountInfo.role;
    return role == Dealer
}

const subdivisionUserGuard = (to, from, next) => {
    const token = store.state.account.token;
    const role = store.state.account.accountInfo.role;

    if (!token || !role) {
        next({name: 'Login'})
    } else if (role != StructuralSubdivisionUser) {
        next('/404')
    }

    next();
}


export {authGuard, loginGuard, dealerGuard, subdivisionUserGuard, disableGuard, isDealerGuard};
