import account from "./entities/account";
import catalog from "./entities/catalog";
import products from "./entities/products";
import cart from "./entities/cart";
import staticPages from "./entities/static-pages";
import banner from "./entities/banner";
import builtInParameter from "./entities/built-in-parameter"
import requestsWriteDownPoints from "./entities/requests-write-down-points";

export default {
    account,
    catalog,
    products,
    cart,
    staticPages,
    banner,
    builtInParameter,
    requestsWriteDownPoints,
}
