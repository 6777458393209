import api from '@/api';
import router from '@/router';

export default {
    namespaced: true,
    state: {
        sectionsList: [],
        currentProductId: null,
        openedSections: {}
    },
    getters: {
        sectionsList: ({sectionsList}) => sectionsList,
        currentProductId: ({currentProductId}) => currentProductId,
        openedSections: ({openedSections}) => openedSections,
        isSectionOpened: (state, getters) => (sectionPath) => getters.openedSections[sectionPath],
    },
    mutations: {
        SET_SECTION(state, sectionsList) {
            state.sectionsList = sectionsList;
        },
        SET_CURRENT_PRODUCT_ID(state, id) {
            state.currentProductId = id
        },
        TOGGLE_SECTION_OPENED(state, sectionPath) {
            const openedSections = state.openedSections;

            state.openedSections = {
                ...openedSections,
                [sectionPath]: !openedSections[sectionPath]
            }

            console.log('toggleSectionOpened:', state.openedSections[sectionPath])
        }
    },
    actions: {
        async fetchSectionList({commit}) {
            try {
                const items = await api.catalog.sections();

                commit('SET_SECTION', items.data.data);
            } catch (e) {
                console.warn(e);
            }
        },
        goToSection({commit}, section) {
            if (section) {
                router.push({
                    name: 'Catalog',
                    params: {
                        slug: section.slug
                    }
                });
            }
        },
        goToCatalog({commit}, {sectionPath, categoryPath = null}) {
            let params = {
                slug: sectionPath
            }

            if (categoryPath) {
                params['slug2'] = categoryPath;
            }

            router.push({name: 'Catalog', params}).catch(()=>{});
        },
        async goToProductCard({commit}, {product: {id}, sectionPath, categoryPath, productPath}) {
            commit('SET_CURRENT_PRODUCT_ID', id)

            if (sectionPath && categoryPath && productPath) {
                router.push({name: 'Product', params: {section: sectionPath, category: categoryPath, product: productPath}});
            }
        },
        async goToProductCardInOrders({commit}, {id, sectionPath, categoryPath, productPath}) {
            commit('SET_CURRENT_PRODUCT_ID', id)

            if (sectionPath && categoryPath && productPath) {
                router.push({name: 'Product', params: {section: sectionPath, category: categoryPath, product: productPath}});
            }
        },
        toggleSection({commit}, sectionPath) {
           commit('TOGGLE_SECTION_OPENED', sectionPath)
        }
    }
}
