import api from '@/api';

export default {
    namespaced: true,
    state: {
        popularProducts: []
    },
    getters: {
      popularProducts: ({popularProducts}) => popularProducts
    },
    actions: {
        async fetchPopularProducts({commit}) {
            try {
                const {data: {data: {items}}} = await api.products.getPopularProducts({});
                commit('SET_POPULAR_PRODUCTS', items)
            } catch (e) {
            }
        }
    },
    mutations: {
        SET_POPULAR_PRODUCTS(state, products) {
            state.popularProducts = products;
        }
    }
}