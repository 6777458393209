import {get, post, put} from "@/lib/axios";

export default {
  async getRequestsWriteDownPoints() {
    return get('/profile/requests')
  },
  async postRequestsWriteDownPoints(data) {
    return post('/profile/create-request', data)
  },
  async putRequestsWriteDownPoints(data) {
    return post('/profile/update-request', data)
  },
  async cancelRequestsWriteDownPoints(data) {
    return post('/profile/cancel-request', data)
  }
}