import axios from 'axios';
import store from '../store';
import {showMessage} from "@/lib/toasted";

const instance = axios.create({
    // baseURL        : (process.env.VUE_APP_API_URL !== undefined) ? process.env.VUE_APP_API_URL : '/api/',
    baseURL        : process.env.VUE_APP_BASE_URL + '/api',
    timeout        : 120000,
    headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'Cache-Control': 'no-cache'
    }
});

instance.interceptors.request.use(config => {
    const token = store.state.account.token;

    if (token) {
        config.headers['Authorization'] = 'Bearer ' + token;
    }

    return config;
});

instance.interceptors.response.use(res => res, async err => {
    const {status} = err.response;

    if (status === 401) {
        await store.dispatch('account/logout', {}, {root: true});
        showMessage('error', 'Ошибка авторизации, пользователь заблокирован!');
    } else if (status === 500) {
        const {data: {status, message}} = err.response;
        showMessage(status, message)
    }

    return Promise.reject(err);
});
export const post = async (url, data = {}, config = {}) => (await instance.post(url, data, config));
export const remove = async (url, config = {}) => (await instance.delete(url, config));
export const patch = async (url, data = {}, config = {}) => (await instance.patch(url, data, config));
export const put = async (url, data = {}, config = {}) => (await instance.put(url, data, config));
export const get  = async (url, params, config = {}) => (await instance.get(url, {params, ...config}));

export const base_url = process.env.VUE_APP_BASE_URL;
