import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./lib/validation";
import "./lib/toasted";
import "./lib/filters";
import "./lib/mixins";
import "./lib/swal2";
import "./lib/vue-screen";
import "./lib/fragment";
import "./lib/viewer";
import "./lib/select";
import "./lib/datepicker";
import VueMeta from "vue-meta";
import VueMask from "v-mask";

Vue.prototype.$log = console.log.bind(console);

Vue.use(VueMask);

Vue.use(VueMeta, {
  refreshOnceOnNavigation: true,
});

new Vue({
  el: "#app",
  router,
  store,
  template: "<App/>",
  components: {
    App,
  },
});
