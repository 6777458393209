import router from "@/router";
import dealer from "./Cart_dealer";
import subdivision from "./Cart_subdivision"
import {removeLoading, setLoading} from "../../lib/loading";
import {showMessage} from "../../lib/toasted";
import api from '@/api';
import {showConfirm} from "@/lib/swal2";


export default {
    namespaced: true,
    modules: {
        dealer,
        subdivision
    },
    state: {
        itemsInCart: 0,
        inCartProductIds: [],
    },
    getters: {
        itemsInCart: ({itemsInCart}) => itemsInCart,
        inCartProductIds: ({inCartProductIds}) => inCartProductIds,
        isProductInCart: (state, getters) => (productId) => {
            return getters.inCartProductIds.indexOf(productId ? productId : null) >= 0
        },
    },
    mutations: {
        SET_ITEMS_IN_CART(state, quantity) {
            state.itemsInCart = quantity
        },
        SET_IN_CART_PRODUCT_IDS(state, productIds) {
            state.inCartProductIds = productIds
        }
    },
    actions: {
        goToCart({rootGetters}) {
            if(rootGetters['account/isCurrentUserDealer']) {
                router.push({name: 'DealerCart'})
            } else if (rootGetters['account/isCurrentUserStructuralSubdivisionUser']) {
                router.push({name: 'SubdivisionUserCart'});
            }
        },
        async addProduct({commit, rootGetters}, {productId, quantity = 1, size= null}) {
            setLoading();

            try {
                const {data: {status, message}} = await api.cart.addItem(productId, quantity, size);

                if (status === 'ok') {
                    commit('cart/SET_ITEMS_IN_CART', rootGetters['cart/itemsInCart'] + quantity, {root: true});

                    if (rootGetters['cart/inCartProductIds'].indexOf(productId) === -1) {
                        commit('cart/SET_IN_CART_PRODUCT_IDS', [...rootGetters['cart/inCartProductIds'], productId], {root: true});
                    }
                }

                removeLoading();
                showMessage(status, message);

                return status === 'ok';
            } catch (e) {
                removeLoading()
                return false;
            }
        },
        async removeProduct({commit, getters: {findProductInCart}, rootGetters}, {productId, quantity= 1, completely = false, size = null}) {
            setLoading();

            try {
                const {data: {status, message}} = await api.cart.removeItem(productId, quantity, completely, size);

                if (status === 'ok') {
                    commit('cart/SET_ITEMS_IN_CART', Math.max(rootGetters['cart/itemsInCart'] - quantity, 0), {root: true});

                    if (completely) {
                        commit('cart/SET_IN_CART_PRODUCT_IDS', rootGetters['cart/inCartProductIds'].filter((id) => id !== productId), {root: true});
                    }
                }

                removeLoading();
                showMessage(status, message);

                console.log('status', status);

                return status === 'ok';
            } catch (e) {
                console.log('e', e);
                removeLoading()
                return false;
            }
        },
        async deleteLine({dispatch}, productId) {
            const {isConfirmed} = await showConfirm('Подтвердите действие', 'Вы уверены, что хотите удалить данный товар из корзины?');

            if (isConfirmed) {
                return await dispatch('removeProduct', ({
                    productId: productId,
                    completely: true
                }));
            }

            return false;
        }
    }
}