import Vue from 'vue'
import Toasted from 'vue-toasted';

Vue.use(Toasted, {
    duration: 6000,
    position: 'bottom-right',
    iconPack: 'material',
    className: 'toast',
    containerClass: 'toast-container',
    keepOnHover: true,
    singleton: true
});

export const toasted = Vue.toasted;

Vue.toasted.register('error_general',  (payload) => {
    if(!payload.message) {
        return 'Что-то пошло не так.'
    }

    return payload.message;
}, {
    type : 'error',
    iconPack: 'material',
    icon : 'gpp_maybe'
});

export const error = (message, options = {}) => {
    return toasted.error(message, {
        icon : 'gpp_maybe',
        ...options
    });
};

export const success = (message, options = {}) => {
    return toasted.success(message, {
        icon : 'done',
        ...options
    });
};

export const showMessage = (status, message) => {
    if (status && message) {
        if (status === 'ok') {
            return success(message)
        } else {
            return error(message);
        }
    }
}