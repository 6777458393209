import { extend } from "vee-validate";
import { required, email, min, integer, min_value, ext, max_value, size, excluded } from "vee-validate/dist/rules";
import fileSizeFormatter from "@/utilities/file-size-formatter";

// No message specified.
extend("email", {
  ...email,
  validate(value) {
    const regexp= /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/;
    return !!value.match(regexp)
  },
  message: "Неверный формат Email",
});

// Override the default message.
extend("required", {
  ...required,
  message: "Данное поле обязательно",
});

extend("excluded", {
  ...excluded,
  message: (text) => `Данное поле не должно быть пустым`,
});

extend("phone", {
  ...required,
  ...min,
  message: "Данное поле должно содержать не менее 11 символов",
});

extend('integer', {
  ...integer,
  message: 'Поле должно содержать целое число'
});

extend('ext', {
  ...ext,
  message: 'Неверный формат файлов'
});

extend('size', {
  ...size,
  message: (_, { size }) => `Файл(ы) превышают максимальный размер - ${fileSizeFormatter(size * 1024)}`
})

extend('maxSize', {
  params: ['size'],
  validate(files, { size }) {
    const maxSizeVal = size
    let countSizes = 0
    if (isNaN(maxSizeVal)) {
      return false;
    }
    let nSize = maxSizeVal * 1024;
    if (!Array.isArray(files)) {
      return files.size <= nSize;
    }
    for (let i = 0; i < files.length; i++) {
      countSizes += files[i].size
    }
    return countSizes <= nSize;
  },
  message: (_, { size }) => `Макс. объем файлов для заявки ${fileSizeFormatter(size * 1000)}`
})

extend('min_value', {
  ...min_value,
  message: 'Поле должно быть больше {min}'
});

extend('max_value', {
  ...max_value,
  message: 'Поле должно быть меньше {max}'
});

extend("min", {
  ...min,
  message: "Данное поле должно содержать не менее {length} символов",
});
