import {get} from '@/lib/axios';

export default {
    async sections() {
        return get('/categories');
    },

    async catalog(slug, slug2 = '') {
        let path = '/categories' + slug;

        if (slug2) {
            path += '/' + slug2;
        }

        return get(path);
    },

    async product(section, category, product) {
        return get(`/products/${section}/${category}/${product}`);
    }
}
