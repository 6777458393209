export default {
  namespaced: true,
  state: {
    courseType: "",
  },
  getters: {
    courseType: (state, getters) => (type) => {
      switch (type) {
        case "technical":
          return "technical";
        case "salesManagers":
          return "for-heads-of-sales";
        case "salesStaff":
          return "for-sales-representatives";
      }

      return (state.courseType = type);
    },
  },
};
