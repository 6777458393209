/**
 * Склонятель существительных, идущих после числа
 * Пример: 1 отзыв, 2 отзыва, 8 отзывов
 */
var DeclensionMaker = /** @class */ (function () {
    function DeclensionMaker() {
    }
    /**
     * Возвращает существительное в нужном склонении в зависимости от числа, за которым оно идёт
     * @param number число
     * @param nounFor1 результат для 1
     * @param nounFor2 результат для 2
     * @param nounFor5 результат для 5
     */
    DeclensionMaker.getDeclension = function (number, nounFor1, nounFor2, nounFor5) {
        var numberAbs = Math.abs(number);
        var titles = [nounFor1, nounFor2, nounFor5];
        var cases = [2, 0, 1, 1, 1, 2];
        return titles[(numberAbs % 100 > 4 && numberAbs % 100 < 20) ? 2 : cases[(numberAbs % 10 < 5) ? numberAbs % 10 : 5]];
    };
    return DeclensionMaker;
}());
export { DeclensionMaker };
