import Vue from "vue";
import Vuex from "vuex";
import common from "../modules/common";
import account from "../modules/account";
import main_page from "../modules/main_page";
import catalog from "../modules/catalog";
import cart from "../modules/cart/Cart";
import staticPages from "../modules/static-pages";
import builtInParameter from "../modules/built-in-parameter";
import course from "@/modules/course-type";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isLoading: false,
  },
  getters: {
    isLoading: ({ isLoading }) => isLoading,
  },
  mutations: {
    SET_LOADING(state) {
      state.isLoading = true;
    },
    REMOVE_LOADING(state) {
      state.isLoading = false;
    },
  },
  actions: {},
  modules: {
    common,
    account,
    course,
    main_page,
    catalog,
    cart,
    staticPages,
    builtInParameter,
  },
});
