import router from './index';
import {Dealer, StructuralSubdivisionUser} from "@/config/roles";
import {DEFAULT_DEALER_URL, DEFAULT_SUBDIVISION_USER_URL } from '@/config/default_urls';

export const redirectToProperIndex = (role) => {
    switch (parseInt(role)) {
        case Dealer :
            router.push(DEFAULT_DEALER_URL);
            break;
        case StructuralSubdivisionUser:
            router.push(DEFAULT_SUBDIVISION_USER_URL);
            break;
    }
};