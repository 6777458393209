import {DeclensionMaker} from "@/utilities/declension-maker.ts";
import config from "@/config/common.json"

export default {
    namespaced: true,
    state: {
        config
    },
    actions: {
        async downloadFileFromUrl({commit}, {fileName, fileUrl}) {
            let response = await fetch(fileUrl);
            let data = await response.blob();

            console.log('filename:', fileName);

            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(data);
            link.download = fileName;
            link.click();
        }
    },
    getters: {
        config: ({config}) => config,
        getConfigValue: (state, getters) => (key) => {
          return getters.config[key];
        },
        getProductImageSrc: () => (imageId, thumbnail = 1) => {
            if (imageId) {
                let imgUrl = `${process.env.VUE_APP_BASE_URL}/api/images/${imageId}`

                if (thumbnail) {
                    imgUrl += `?thumbnail=${thumbnail}`;
                }

                return imgUrl;
            }
        },
        getFileSrc: () => (fileId) => {
            if (fileId) {
                return `${process.env.VUE_APP_BASE_URL}/api/files/${fileId}`
            }
        },
        makeDeclension: () => (price) => {
            return DeclensionMaker.getDeclension(price, "балл", "балла", "баллов")
        }
    }
}